'use client';

import { Suspense, useEffect, useState } from 'react';
import { useWebsiteAPI } from '@/hooks/useWebsiteAPI';
import { useRouter } from 'next/navigation';
import { PromptBarUI } from '@/components/PromptBar';
import { WebsitesSection } from '@/components/WebsiteList/discover';
import { Footer } from '@/components/Footer';
import { HomeHeader } from '@/components/HomeHeader';
import { CREATE_INPUT_STORAGE_KEY } from '@/utils/storage';
import { useUser } from '@/hooks/useUser';
export default function Home() {
  const router = useRouter();
  const [prompt, setPrompt] = useState<string | null>(null);
  const {
    hasLogin
  } = useUser();
  useEffect(() => {
    const fetchDid = async () => {
      const response = await fetch('/api/auth/did');
      const data = await response.json();
      localStorage.setItem('user_did', data.did);
    };
    if (!hasLogin) {
      const storedDid = localStorage.getItem('user_did');
      if (!storedDid) {
        fetchDid();
      }
    }
  }, [hasLogin]);
  useEffect(() => {
    const input = localStorage.getItem(CREATE_INPUT_STORAGE_KEY);
    if (input && prompt === null) {
      setPrompt(input);
    }
  }, []);
  useEffect(() => {
    if (prompt === null) {
      return;
    }
    if (prompt) {
      localStorage.setItem(CREATE_INPUT_STORAGE_KEY, prompt);
    } else {
      localStorage.removeItem(CREATE_INPUT_STORAGE_KEY);
    }
  }, [prompt]);
  const {
    generate,
    loading,
    error
  } = useWebsiteAPI();
  const handleSubmit = async (prompt: string, image: string | null) => {
    console.log({
      prompt,
      image
    });
    const {
      id: generatedId
    } = await generate(prompt, image);
    if (generatedId) {
      localStorage.removeItem(CREATE_INPUT_STORAGE_KEY);
      router.push(`/site/${generatedId}`);
    }
  };
  return <div className="flex flex-col min-h-[100vh] relative" data-sentry-component="Home" data-sentry-source-file="page.tsx">
      <main className="container mx-auto p-4 lg:p-8 flex-1 relative z-10">
        <HomeHeader data-sentry-element="HomeHeader" data-sentry-source-file="page.tsx" />

        <PromptBarUI mode="create" prompt={prompt || ''} setPrompt={setPrompt} onGenerate={handleSubmit} loading={loading} data-sentry-element="PromptBarUI" data-sentry-source-file="page.tsx" />

        {error && <div className="text-red-500 mb-4">{error}</div>}

        <Suspense fallback={<div>Loading...</div>} data-sentry-element="Suspense" data-sentry-source-file="page.tsx">
          <WebsitesSection className="my-24" data-sentry-element="WebsitesSection" data-sentry-source-file="page.tsx" />
        </Suspense>
      </main>
      <Footer data-sentry-element="Footer" data-sentry-source-file="page.tsx" />
    </div>;
}