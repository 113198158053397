import React, { forwardRef } from 'react';
import Image from 'next/image';
import { Website } from '@repo/common/entities';
import { FloatingCard } from '@/components/FloatingCard';
import { PUBLIC_SITE_URL, PUBLIC_S3_PUBLIC_PREFIX } from '@/utils/constants';
import { UserInfo } from '../user/UserAvatar';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { useMetrics } from '@/hooks/useMetrics';
export const WebsiteCard = forwardRef<HTMLAnchorElement, {
  website: Website;
}>(({
  website
}, ref) => {
  const imageUrl = `${PUBLIC_S3_PUBLIC_PREFIX}/website-version/${website.currentVersionId}/preview`;
  const {
    recordEvent
  } = useMetrics();
  const handleClick = () => {
    recordEvent('website_card_click', {
      website_id: website.id,
      website_title: website.title
    });
  };
  return <Link href={`${PUBLIC_SITE_URL}/site/${website.id}`} onClick={handleClick} ref={ref}>
      <FloatingCard className="w-full cursor-pointer">
        {({
        isHovered
      }) => <div className="relative">
            <div className="aspect-video relative">
              <Image src={imageUrl} alt={website.title ?? ''} layout="fill" objectFit="cover" className="rounded-md" />
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col items-between">
              {website.OwnByUserProfile && <div className="absolute top-0 left-0 w-full p-2 flex items-between">
                  <div className={cn('p-2 rounded-sm transition-all', isHovered && 'bg-black/10')}>
                    {website.OwnByUserProfile && <UserInfo className={cn('transition-all text-white opacity-60', isHovered ? 'opacity-100' : 'opacity-70')} profile={website.OwnByUserProfile} size="sm" avatarOnly={!isHovered} />}
                  </div>
                  <div></div>
                </div>}
              <div className={`absolute bottom-0 left-0 w-full p-4 transition-all bg-gradient-to-t ${isHovered ? 'from-black/60' : 'from-black/50'} to-transparent`}>
                <h3 className="text-white text-base font-bold truncate shadow-sm" title={website.title ?? ''}>
                  {website.title ?? ''}
                </h3>
                <div className={`transition-all ${isHovered ? 'max-h-[1000px]' : 'max-h-0'} overflow-hidden`}>
                  <p className="text-xs text-white opacity-80">
                    {website.description}
                  </p>
                </div>
              </div>
            </div>
          </div>}
      </FloatingCard>
    </Link>;
});
WebsiteCard.displayName = 'WebsiteCard';